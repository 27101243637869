// @todo enable next eslint rules
/* eslint-disable no-shadow */
import React from 'react';
import Helmet from 'react-helmet';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import Layout from '../../layouts/SimplePage';
import Calculator from '../../components/Calculator';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import Criteria from '../../components/Criteria';
import {
  CommonPageSection,
  Content,
  ContentItemWrap,
  ContentItem,
  Sidebar,
  LinksBlock,
} from '../../components/CommonPageSection';
import CTA from '../../components/CTA';


import './Tabs.scss';
import './index.scss';


/**
 * @todo rewrite as stateless function
 */
const QualityAssurancePage = () => (
  <Layout
    pageWrapperClassName="quality-assurance-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Quality Assurance | TakeAwayEssay.com"
      meta={[
        { name: 'description', content: '' },

        { name: 'robots', content: 'noindex,nofollow' },

        { property: 'og:title', content: 'Quality Assurance | TakeAwayEssay.com' },
        { property: 'og:description', content: '' },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/quality-assurance.html' },
      ]}
    />

    <CommonPageSection>
      <Content>
        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="common-page"
            dotsStyle="style-4"
            text="Quality"
          />

          <ContentItem className="get-desired-grade-section">
            <h1>
              Get a desired grade for
              <br />
              {' '}
              your custom-written essay
            </h1>
            <p className="common-page-section__content-item__custom-paragraph">
              Our advanced algorithm of controlling writing quality
              <br />
              {' '}
              comprises the following features
            </p>
            <div className="quality__wrap quality__wrap-features">
              <div className="quality__item">
                <h3>
                  Adherence to instructions
                </h3>
                <p>
                  Our writers meet all your professor’s requirements and stick to your
                  custom guidelines, regardless of their complexity.
                </p>
              </div>
              <div className="quality__item">
                <h3>
                  Compliance with grading criteria
                </h3>
                <p>
                  Our dedicated QA team analyzes each essay to see if it matches your
                  personal or general assessment criteria.
                </p>
              </div>
              <div className="quality__item">
                <h3>
                  Proper referencing
                </h3>
                <p>
                  Our editors check not only grammar and tone of voice, but also if
                  formatting and references are correct.
                  {' '}
                </p>
              </div>
              <div className="quality__item">
                <h3>
                  Authentic essays
                </h3>
                <p>
                  Our quality assurance experts always check every paper for plagiarism to
                  make sure there’s no cheating.
                </p>
              </div>
            </div>
          </ContentItem>
        </ContentItemWrap>

        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="common-page"
            dotsStyle="style-4"
            text="Criteria"
          />
          <ContentItem>
            <h2>
              Basic grading criteria for
              <br />
              {' '}
              written assignments we rely on
            </h2>
            <p>
              In case you don’t have any specific requirements to your essay, we will check
              it according to our basic grading scheme. To create it, we have analyzed
              assessment criteria of the 70 major universities in the US. Based on
              it, we have developed common categorization of marks for students’
              achievements, which you can find in the table below.
            </p>

            <Tabs
              className="tabs-section"
            >
              <TabList className="tabs-section__list">
                <Tab className="tabs-section__tab">
                  A-Level Grade A+ Undergraduate 1st Master&#39;s Distinction
                </Tab>
                <Tab className="tabs-section__tab">
                  A-Level Grade A Undergraduate 2:1 Master&#39;s Merit
                </Tab>
                <Tab className="tabs-section__tab">
                  A-Level Grade B Undergraduate 2:2 Master&#39;s Pass
                </Tab>
              </TabList>
              <TabPanel className="tabs-section__content">
                <Criteria
                  scale="70-100"
                  topic="Comprehensive understanding of the topic and materials"
                  argumentation="Strong or forceful reasoning"
                  criticalApproach="Excellent analysis and evaluation of facts"
                  structure="Superior and well-thought-out structure"
                  styleAndGrammar="Fluent language, precise word choice, perfect grammar"
                  evidence="Powerful factual evidence"
                  sources="Extensive use of credible sources"
                />
              </TabPanel>
              <TabPanel className="tabs-section__content">
                <Criteria
                  scale="60-69"
                  topic="Substantive understanding of the topic and materials"
                  argumentation="Great argument and reasoning"
                  criticalApproach="Sufficient and mindful approach"
                  structure="Good and clear structure"
                  styleAndGrammar="Strong command of English, great style"
                  evidence="Very good evidence"
                  sources="Quite good use of sources"
                />
              </TabPanel>
              <TabPanel className="tabs-section__content">
                <Criteria
                  scale="50-59"
                  topic="Moderate understanding"
                  argumentation="Good argumentation, but not in all parts"
                  criticalApproach="Acceptable critical judgment"
                  structure="Normal structure, but it doesn’t cover the topic to the full extent"
                  styleAndGrammar="Good writing style, but a bit clumsy; minor mistakes"
                  evidence="Enough evidence, but with occasional gaps"
                  sources="Satisfying use of sources"
                />
              </TabPanel>
            </Tabs>

          </ContentItem>
        </ContentItemWrap>
      </Content>
      <Sidebar>
        <LinksBlock />
      </Sidebar>
    </CommonPageSection>

    <Calculator />

    <CommonPageSection>
      <Content>
        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="quality-guarantees"
            dotsStyle="style-4"
            text="Guarantees"
          />
          <ContentItem>
            <h2 className="common-page-section__content-item__custom-heading">
              Check our basic free guarantees
            </h2>
            <div className="quality__wrap quality__wrap-guarantees">
              <div className="quality__item">
                <h3>
                  Free revisions
                </h3>
                <p>
                  If a paper happens not to meet your expectations for some reason, we’ll
                  revise it for free
                </p>
              </div>
              <div className="quality__item">
                <h3>
                  Money back guarantee
                </h3>
                <p>
                  In case you have found some issues with your paper, we will give you a
                  refund
                </p>
              </div>
              <div className="quality__item">
                <h3>
                  Full confidentiality
                </h3>
                <p>
                  We highly appreciate your trust and never share your personal information
                  with anyone
                </p>
              </div>
              <div className="quality__item">
                <h3>
                  Plagiarism-free guarantee
                </h3>
                <p>
                  We always scan our papers with special plagiarism detection software
                  before delivery
                </p>
              </div>
            </div>
          </ContentItem>
        </ContentItemWrap>

      </Content>
    </CommonPageSection>

    <CTA>
      Want your essay to meet the highest quality standards?
      <br />
      {' '}
      Enter your paper’s details and our team will readily
      <br />
      {' '}
      help you meet them!
    </CTA>

  </Layout>
);

export default QualityAssurancePage;
