// @todo enable next eslint rules
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


/**
 * @todo rename filename
 * Each component directory should have root entry.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
 * @see https://developer.mozilla.org/en-US/docs/web/javascript/reference/statements/export
 * @example
 * // "/components/SingleComponent/index.jsx"
 * import React from 'react';
 * import SingleComponentChild from './SingleComponentChild';
 *
 *
 * const SingleComponent = () => (
 *   <SingleComponentChild />
 * );
 *
 * export default SingleComponent;
 *
 * // "/components/MultiComponent/index.js"
 * export { default as MultiComponentChild } from './MultiComponentChild';
 * export { default as MultiComponent } from './MultiComponent';
 * export { default } from './MultiComponent';
 *
 * // "/pages/index.jsx"
 * import React, { Fragment } from 'react';
 * import SingleComponent from '../../components/SingleComponent;
 * import MultiComponent, { MultiComponentChild } from '../../components/MultiComponent';
 *
 * export default () => (
 *   <Fragment>
 *     <SingleComponent />
 *     <MultiComponent>
 *       <MultiComponentChild />
 *     </MultiComponent>
 *   </Fragment>
 * );
 *
 * @todo use destructuring assignment for passing props
 * @example
 * const Component = ({ children }) => (
 *  <div>
 *    {children}
 *  </div>
 * );
 *
 * @todo add defaults to optional props
 * @example
 * Component.propTypes = {
 *  activate: PropTypes.func.isRequired,
 *  active: PropTypes.bool,
 *  children: PropTypes.node,
 * };
 * Component.defaultProps = {
 *  active: false,
 *  children: undefined,
 * };
 */
const Criteria = ({
  scale,
  topic,
  argumentation,
  criticalApproach,
  structure,
  styleAndGrammar,
  evidence,
  sources,
}) => (
  <div className="criteria-item">
    <div className="criteria-item__table">
      <div className="criteria-item__table__label">
        On 0-100 scale
      </div>
      <div className="criteria-item__table__text">
        {scale}
      </div>
      <div className="criteria-item__table__label">
        Topic knowledge and understanding
      </div>
      <div className="criteria-item__table__text">
        {topic}
      </div>
      <div className="criteria-item__table__label">
        Argumentation
      </div>
      <div className="criteria-item__table__text">
        {argumentation}
      </div>
      <div className="criteria-item__table__label">
        Critical approach
      </div>
      <div className="criteria-item__table__text">
        {criticalApproach}
      </div>
      <div className="criteria-item__table__label">
        Structure
      </div>
      <div className="criteria-item__table__text">
        {structure}
      </div>
      <div className="criteria-item__table__label">
        Style and grammar
      </div>
      <div className="criteria-item__table__text">
        {styleAndGrammar}
      </div>
      <div className="criteria-item__table__label">
        Evidence
      </div>
      <div className="criteria-item__table__text">
        {evidence}
      </div>
      <div className="criteria-item__table__label">
        Sources
      </div>
      <div className="criteria-item__table__text">
        {sources}
      </div>
    </div>
  </div>
);

Criteria.propTypes = {
  scale: PropTypes.string,
  topic: PropTypes.string,
  argumentation: PropTypes.string,
  criticalApproach: PropTypes.string,
  structure: PropTypes.string,
  styleAndGrammar: PropTypes.string,
  evidence: PropTypes.string,
  sources: PropTypes.string,
};

Criteria.defaultProps = {
  scale: undefined,
  topic: undefined,
  argumentation: undefined,
  criticalApproach: undefined,
  structure: undefined,
  styleAndGrammar: undefined,
  evidence: undefined,
  sources: undefined,
};

export default Criteria;
